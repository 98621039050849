import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

const apiDomain = environment.apiUrl + '/StockLocation';
const url = {
  get: apiDomain + '/get',
  list: apiDomain + '/list',
  add: apiDomain + '/add',
  update: apiDomain + '/update',
  delete: apiDomain + '/delete'
};

@Injectable({
  providedIn: 'root'
})
export class StockLocationService {

  constructor(private http: HttpClient) {

  }

  getLocation(searchParams: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL =  `${url.get}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=UTF-8',
        }),
        params: searchParams
      };
      this.http.get(apiURL, httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  list(requestParams) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = url.list;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=UTF-8',
        }),
        params: requestParams,
      };
      this.http.get(apiURL, httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  add(body: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = url.add;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=UTF-8'
        })
      };
      this.http.post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  update(body: any) {
    const promise = new Promise((resolve, reject) => {
      const apiURL = url.update;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=UTF-8'
        })
      };
      this.http.post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
