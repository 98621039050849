import { Deserializable } from '../../shared/interfaces/deserializable';
import { Product } from './product.model';
import { OrderDetail } from './orderDetail.model';

export class Order implements Deserializable {
  constructor() {
    this.orderDetails = [];
    this.total = 0;
    this.price = 0;
    this.totalPaid = 0;
    this.totalChange = 0;
    this.discount = 0;
  }
  id: string;
  action: number;
  shopId: string;
  shopCode: string;
  shopName: string;
  orderNo: string;
  invoiceNo: string;
  receiptNo: string;
  price: number;
  qty: number;
  discount: number;
  discountType: number;
  promotionCode: string;
  orderStatusId: string;
  orderStatus: string;
  billingStatusId: string;
  billingStatus: string;
  createBy: string;
  tableId: string;
  tableName: string;
  currencyId: string;
  currencyCode: string;
  orderDetails: Array<OrderDetail>;
  products: Array<Product>;
  totalPaid: number;
  totalChange: number;
  customer: string;
  created: string;
  updated: string;
  total: number;
  member: string;
  table: string;
  cashier: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
