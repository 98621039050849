import { TagModel } from "ngx-chips/core/accessor";
import { Image } from "../../core/models";
import { Deserializable } from "../../shared/interfaces/deserializable";
import { Product } from "./product.model";

export class Campaign implements Deserializable {
  id: string;
  shopCode: string;
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  expiredFrom: Date;
  expiredTo: Date;
  limit: number;
  price: number;
  imageId: string;
  imageURL: string;
  lastUpdate: Date;
  imageCollections: Array<Image> = [];
  products: Array<any>;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
