import { Deserializable } from '../../shared/interfaces/deserializable';

export class OrderDetail implements Deserializable {
  id: string;
  productId: string;
  productName: string;
  productCode: string;
  price: number;
  totalPrice: number;
  qty: number;
  discount: number;
  discountType: number;
  status: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
