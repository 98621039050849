import { Deserializable } from '../../shared/interfaces/deserializable';
import { Image, Tag } from '../../core/models/index';

export class Product implements Deserializable {
    id: string;
    shopCode: string;
    name: string;
    code: string;
    serialNumber: string;
    ownerName: string;
    description: string;
    longDescription: string;
    price: number;
    cost: number;
    unit: string;
    category: string;
    tags: Array<Tag>;
    profileImage: Image;
    coverImage: Image;
    images: Array<Image>;
    status: number;
    acceptPartner: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
